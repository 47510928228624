import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router'
import FastInLayout from "@/views/shared/FastInLayout.vue";
import AuthModule from "@/views/auth/AuthModule";
import CompetitionModule from "@/views/competition/CompetitionModule";
import {useCompetitionStore} from "@/stores/Competition";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: FastInLayout,
        children: [
            ...AuthModule.routes,
            ...CompetitionModule.routes
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
    const competitionStore = useCompetitionStore()
    if (competitionStore.hasCode) {
        next()
    } else {
        const code = localStorage.getItem("competition_code")
        if (to.name != 'code') {
            next({name: 'code', query: {'f': to.path, 'code': to.query['code'] || code}})
        } else {
            next({query: {'code': to.query['code'] || code}})
        }
    }
})

export default router
