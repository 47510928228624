

















import {Prop, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import {JumpingSportResultInput, SportResult, TestResult, SportResultStatus} from "@/types/competition/SportResult";
import VueNumericInput from "@/components/vue-numeric-input.vue";

@Component({
  components: {VueNumericInput}
})
export default class TimeComponent extends Vue {
  @Prop() result!: JumpingSportResultInput

  focus() {
    (this.$refs["input"] as HTMLInputElement)?.select()
  }

  @Watch("result.time")
  onTimeChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (this.result.time > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = SportResultStatus.FINISHED
    }
    this.$emit("changed")
  }

  isDisabled() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return (this.result.status !== SportResultStatus.NOT_STARTED && this.result.status !== SportResultStatus.FINISHED)
  }
}
