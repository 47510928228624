












import {Component, Prop, Vue} from 'vue-property-decorator';
import {HorseName} from "@/types/competition/Practitioner";

@Component
export default class HorseComponent extends Vue {
  @Prop({required: true}) horse!: HorseName;
  @Prop({default: true}) active!: boolean;
}
