import axios from 'axios'

function errorResponseHandler() {
    // localStorage.clear()
    // window.location.reload()
}

// apply interceptor on response


export default errorResponseHandler;