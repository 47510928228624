
import {Component, Vue} from 'vue-property-decorator';
import {SportResultStatus} from "@/types/competition/SportResult";


@Component
export default class TestResultMixin extends Vue {

  isTestResultStatusNotStarted(status: SportResultStatus) {
    return status == SportResultStatus.NOT_STARTED
  }

  isTestResultStatusFinished(status: SportResultStatus) {
    return status == SportResultStatus.FINISHED
  }

  isTestResultStatusNotFinished(status: SportResultStatus) {
    return status !== SportResultStatus.FINISHED
  }

  testResultStatusVariant(status: SportResultStatus) {
    switch (status) {
      case SportResultStatus.DISQUALIFIED:
      case SportResultStatus.WITHDRAWN:
      case SportResultStatus.RETIRED:
      case SportResultStatus.ELIMINATED:
        return "danger"
      case SportResultStatus.FINISHED:
        return "success"
      default:
        return "info"
    }
  }
}
