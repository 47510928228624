














import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {
    DressageScoreResultInput,
    DressageSportResultManualInput,
    SportResultStatus
} from "@/types/competition/SportResult";
import {DressageManualMarker} from "@/types/competition/Activity";
import MarkerModal from "@/components/result/MarkerModal.vue";
import {PractitionerResultData} from "@/types/competition/Practitioner";

@Component({})
export default class MarkersComponent extends Vue {
    @Prop() data!: PractitionerResultData
    @Prop() markers!: Array<DressageManualMarker>
    @Prop() result!: DressageSportResultManualInput
    @Prop() markerModal!: MarkerModal

  focus() {
    (this.$refs["input"] as HTMLElement)?.focus()
  }

    mounted() {
        this.$watch(`result.markerPoints`, this.onValueChange)
    }

    modalForMarker(m: DressageManualMarker): DressageScoreResultInput | undefined {
        if (!this.result.markerPoints) {
            return undefined
        }
        return this.result.markerPoints.find(it => it.id == m.id)
    }

    showModal() {
        this.markerModal.show(this.data, this.result, this.markers)
    }

    get summary(): string {
        return this.markers.map(m => {
            const v = this.modalForMarker(m)
            if (v) {
                return v.value.toString()
            } else {
                return "0"
            }
        }).join("/")
    }

    onValueChange() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (this.result[this.field] > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.result.status = SportResultStatus.FINISHED
        }
        this.$emit("changed")
    }

    isDisabled() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return (this.result.status !== SportResultStatus.NOT_STARTED && this.result.status !== SportResultStatus.FINISHED)
    }
}
