












import {Component, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import TestResultStatusComponent from "@/components/result/field/TestResultStatusComponent.vue";
import TestResultMixin from "@/components/result/field/TestResultMixin.vue";
import DressageResultComponent from "@/components/result/field/DressageResultComponent.vue";
import JumpingResultComponent from "@/components/result/field/JumpingResultComponent.vue";
import CrossCountryResultComponent from "@/components/result/field/CrossCountryResultComponent.vue";
import EventingResultComponent from "@/components/result/field/EventingResultComponent.vue";
import PercentageResultComponent from "@/components/result/field/PercentageResultComponent.vue";
import PointsResultComponent from "@/components/result/field/PointsResultComponent.vue";
import {RankingField, RankingFieldType} from "@/types/competition/Ranking";


@Component({
  components: {
    EventingResultComponent,
    PercentageResultComponent,
    PointsResultComponent,
    DressageResultComponent, CrossCountryResultComponent, JumpingResultComponent, TestResultStatusComponent
  }
})
export default class TableCellRankingFieldComponent extends mixins(Vue, TestResultMixin) {
  @Prop({required: true}) result!: RankingField

  get isPoints() {
    return this.result && this.result.type == RankingFieldType.POINTS
  }

  get isPercentage() {
    return this.result && this.result.type == RankingFieldType.PERCENTAGE
  }

  get isShowJumpingResult() {
    return this.result && this.result.type == RankingFieldType.SHOW_JUMPING
  }

  get isDressageResult() {
    return this.result && this.result.type == RankingFieldType.DRESSAGE
  }

  get isCrossCountryResult() {
    return this.result && this.result.type == RankingFieldType.CROSS_COUNTRY
  }

  get isEventing() {
    return this.result && this.result.type == RankingFieldType.EVENTING
  }
}
