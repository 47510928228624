

































import {Ref, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {DressageManualMarker} from "@/types/competition/Activity";
import {DressageScoreResultInput, DressageSportResultManualInput} from "@/types/competition/SportResult";
import {BModal} from "bootstrap-vue";
import {PractitionerResultData} from "@/types/competition/Practitioner";

@Component
export default class MarkerModal extends Vue {
    data: PractitionerResultData = {} as PractitionerResultData
    markers: Array<DressageManualMarker> = []
    result: DressageSportResultManualInput = {} as DressageSportResultManualInput
    private visible = false
    @Ref("markerModal") modal!: BModal

    show(data: PractitionerResultData, result: DressageSportResultManualInput, markers: Array<DressageManualMarker>) {
        this.data = data
        this.result = result
        this.markers = markers
        this.modal.show()
    }

    markerModel(m: DressageManualMarker): DressageScoreResultInput {
        if (!this.result.markerPoints) {
            this.result.markerPoints = []
        }
        let marker = this.result.markerPoints.find(it => it.id == m.id)
        if (!marker) {
            marker = {id: m.id, value: m.defaultValue, remarks: ""} as DressageScoreResultInput
            this.result.markerPoints.push(marker)
        }
        return marker
    }

    emitOk() {
        this.$emit("ok", this.data)
    }
}
