import {
    CrossCountryObstacle,
    CrossCountryObstacleErrorType,
    JumpingPenaltyTable,
    SportResultDataInputType,
    SportResultDataType,
    SportResultStatus
} from "@/types/competition/SportResult";
import {RefWithName} from "@/types/competition/General";
import {PersonName} from "@/types/competition/Practitioner";

export enum SportType {
    JUMPING = "Jumping",
    DRESSAGE = "Dressage"
}

export interface SportActivities {
    sportType: SportType,
    activities: Array<Activity>
}

export interface Activity {
    ref: string,
    name: string,
    sportType: SportType,
}

export const initActivity = () => {
    return {
        ref: "",
        name: "",
        sportType: SportType.JUMPING
    }
}

//Headers
export interface ActivityHeader {
    startlistRef: string,
    bindingName: string,
    startlistGroupName: string,
    startlistSetName: string,
    hasMultipleStartlists: boolean,
    sportTypeVariantName: string,
    careerNames: Array<string>,
    championshipNames: Array<string>,
    resultHeaders: Array<ResultHeader>,
    rankings: Array<RefWithName>
}

export interface ResultHeader {
    name: string,
    order: number,
    testResultId: string,
    type: SportResultDataInputType
}

export interface JumpingResultHeader extends ResultHeader {
    obstacles: Array<string>
}

export interface CrossCountryResultHeader extends ResultHeader {
    obstacles: Array<CrossCountryObstacle>,
    obstacleErrorSpecifications: Array<CrossCountryObstacleErrorSpecification>
}

export interface CrossCountryObstacleErrorSpecification extends CrossCountryObstacleErrorHolder {
    type: CrossCountryObstacleErrorType,
    noError?: boolean,
    maxCount: number,
    states?: Array<CrossCountryObstacleErrorStateSpecification>
}

export interface CrossCountryObstacleErrorStateSpecification extends CrossCountryObstacleErrorHolder {
    condition: CrossCountryObstacleErrorStateConditionSpecification
}

export interface CrossCountryObstacleErrorStateConditionSpecification {
    count: number
}

export interface CrossCountryObstacleErrorHolder {
    penalties?: number,
    status?: SportResultStatus,
}

export interface DressageManualResultHeader extends ResultHeader {
    markers: Array<DressageManualMarker>
}


export interface DressageManualMarker {
    id: string;
    step: string;
    title: string;
    description: string;
    location: string;
    excludeFromScore: boolean;
    required: boolean;
    maxValue: number;
    minValue: number;
    stepValue: number;
    defaultValue: number;
}

export enum TableFieldType {
    OBSTACLE = "OBSTACLE",
    CROSS_COUNTRY_OBSTACLE = "CROSS_COUNTRY_OBSTACLE",
    CROSS_COUNTRY_TIME = "CROSS_COUNTRY_TIME",
    TIME = "TIME",
    STATUS = "STATUS",
    JUMPING_TIME_ERRORS = "JUMPING_TIME_ERRORS",
    JUMPING_OBSTACLE_ERRORS = "JUMPING_OBSTACLE_ERRORS",
    POINTS = "POINTS",
    MANUAL_MARKERS = "MANUAL_MARKERS",
    OUT_OF_COMPETITION = "OUT_OF_COMPETITION"
}

export interface TableField {
    key: string,
    field: string,
    type: TableFieldType,
    order: number,
    testResultId: string
    label: string,
    tdClass?: string,
    thClass?: string,

    [key: string]: any
}

export interface TestResultHeader {
    name: string,
    resultType: SportResultDataType,
    testResultId: string
}

/*
* Jumping Header
* */
export interface JumpingTestResultHeader extends TestResultHeader {
    round: number
    protocol: RefWithName,
    penaltyTable: JumpingPenaltyTable,
    courseDesigner?: PersonName
    timingResponsible?: PersonName
    jury?: PersonName
    courseDesignUrl?: string,
    allowedTime?: number
    timeLimit?: number
    countdownTime?: number
}

export interface Obstacle {
    order: number;
    name: string;
}

/*
* Dressage Header
* */
export interface DressageTestResultHeader extends TestResultHeader {
    juryPosition?: DressageJuryPosition
}

export enum DressageJuryPosition {
    A = "A",
    F = "F",
    B = "B",
    M = "M",
    C = "C",
    H = "H",
    E = "E",
    K = "K",
    D = "D",
    X = "X",
    G = "G",
    P = "P",
    R = "R",
    S = "S",
    V = "V",
    L = "L",
    I = "I"
}
