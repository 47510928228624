import {RouteConfig} from "vue-router";
import AuthLayout from "@/views/auth/AuthLayout.vue";
import AuthView from "@/views/auth/AuthView.vue";

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: AuthLayout,
        children: [
            {
                path: '/',
                name: 'code',
                component: AuthView
            }
        ]
    }
]

export default {
    routes: routes
};