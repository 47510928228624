import {CompetitionInfo} from "@/types/competition/Competition";
import axios from "axios";

class CompetitionService {
    public getCompetitionByCode(code: string): Promise<CompetitionInfo> {
        const uninterceptedAxiosInstance = axios.create()
        return uninterceptedAxiosInstance.get(`/api/fastin/competition/${code}`, {
            headers: {
                'code': code
            }
        })
    }
}

export const competitionService = new CompetitionService()