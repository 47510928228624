export interface CompetitionInfo {
    id: string,
    name: string,
    avatar?: string,
    start: string,
    end: string,
}

export const initCompetitionInfo = () => {
    return {
        id: "",
        name: "",
        avatar: undefined,
        start: "",
        end: "",
    }
}