import {defineStore} from "pinia";
import {Activity, initActivity} from "@/types/competition/Activity";

export const useStateStore = defineStore('stateStore', {
    state: () => ({
        selectedActivity: initActivity()
    }),
    getters: {
        getSelectedActivity(): Activity {
            return this.selectedActivity
        }
    },
    actions: {
        setSelectedActivity(selected: Activity) {
            this.selectedActivity = selected
        }
    }
})