















































import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {mapStores} from "pinia";
import {useStateStore} from "@/stores/State";
import {Activity} from "@/types/competition/Activity";
import {Menu} from "@/service/ActivityService";
import BindingMenuComponent from "@/components/BindingMenuComponent.vue";

@Component({
  components: {BindingMenuComponent},
  computed: {
    ...mapStores(useStateStore)
  }
})
export default class CompetitionSideBar extends Vue {
  @Prop() competitionRef!: string
  @Prop() menu!: Menu

  isActive(ref: string) {
    return ref == this.$route.params.activity
  }

  goToRoute(url: string, activity: Activity) {
    const stateStore = useStateStore()
    stateStore.setSelectedActivity(activity)
    this.$router.push(url)
  }

}
