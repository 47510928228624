




















import {Prop, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import {CrossCountryObstacleErrorSpecification} from "@/types/competition/Activity";
import {
  CrossCountryObstacle,
  CrossCountryObstacleErrorType,
  CrossCountrySportResultInput,
  SportResultStatus
} from "@/types/competition/SportResult";
import CrossCountryObstacleModal from "@/components/result/CrossCountryObstacleModal.vue";
import {
  CrossCountryObstacleErrorSpecificationUi,
  CrossCountryPenaltiesHelper
} from "@/components/result/CrossCountryPenaltiesHelper";
import {PractitionerResultData} from "@/types/competition/Practitioner";

@Component({})
export default class CrossCountryObstacleComponent extends Vue {
  @Prop() data!: PractitionerResultData
  @Prop() obstacle!: CrossCountryObstacle
  @Prop() obstacles!: Array<CrossCountryObstacle>
  @Prop() result!: CrossCountrySportResultInput
  @Prop() index!: number
  @Prop() obstacleModal!: CrossCountryObstacleModal
  @Prop() obstacleErrorSpecifications!: Array<CrossCountryObstacleErrorSpecification>

  helper: CrossCountryPenaltiesHelper = new CrossCountryPenaltiesHelper(this.obstacleErrorTypes, this.result)

  focus() {
    (this.$refs["input"] as HTMLElement)?.focus()
  }

  mounted() {
    this.newHelper()
  }

  @Watch("obstacleErrorSpecifications")
  @Watch("result")
  newHelper() {
    this.helper = new CrossCountryPenaltiesHelper(this.obstacleErrorTypes, this.result)
  }


  get obstacleErrorTypes() {
    return this.obstacleErrorSpecifications.map(it => {
      return {
        ...it,
        label: i18n.label(it.type),
        shortLabel: i18n.shortLabel(it.type),
        variant: it.noError ? "success" : "danger"
      } as CrossCountryObstacleErrorSpecificationUi
    })
  }

  popoverTrigger() {
    this.obstacleModal.show(this.data, this.result, this.obstacle, this.obstacleErrorTypes, this.obstacles)
  }

  handleEnter(event: any) {
    event.preventDefault()
  }

  handleNumberKeys(event: any) {
    const obstacle = this.helper.getObstacleResult(this.obstacle)
    const index = parseInt(event.key)
    if (isNaN(index) || index < 0 || index >= this.obstacleErrorSpecifications.length) {
      return
    }
    obstacle.errors = [this.obstacleErrorSpecifications[index].type]
  }

  handlePlusMinusKey(event: any) {
    this.handleNumberKeys(event)
    this.$emit("changed")
  }

  isDisabled() {
    return (this.result.status !== SportResultStatus.NOT_STARTED && this.result.status !== SportResultStatus.FINISHED)
  }
}

const i18n = {
  label(type: CrossCountryObstacleErrorType) {
    switch (type) {
      case CrossCountryObstacleErrorType.NO_ERROR:
        return "Geen fout"
      case CrossCountryObstacleErrorType.FLAGGED_OBSTACLE:
        return "Afgevlagde hindernis"
      case CrossCountryObstacleErrorType.FALL_HORSE:
        return "Paard gevallen"
      case CrossCountryObstacleErrorType.FALL_RIDER:
        return "Ruiter gevallen"
      case CrossCountryObstacleErrorType.SAFETY_FLAP:
        return "Veiligheidsklep"
      case CrossCountryObstacleErrorType.NOT_TAKEN:
        return "Niet genomen"
      case CrossCountryObstacleErrorType.DANGEROUS:
        return "Gevaarlijk rijden"
      case CrossCountryObstacleErrorType.REFUSAL:
        return "Wijgering"
      default:
        return "???"
    }
  },
  shortLabel(type: CrossCountryObstacleErrorType) {
    switch (type) {
      case CrossCountryObstacleErrorType.NO_ERROR:
        return "0"
      case CrossCountryObstacleErrorType.FLAGGED_OBSTACLE:
        return "FLAG"
      case CrossCountryObstacleErrorType.FALL_HORSE:
        return "VP"
      case CrossCountryObstacleErrorType.FALL_RIDER:
        return "VR"
      case CrossCountryObstacleErrorType.SAFETY_FLAP:
        return "VK"
      case CrossCountryObstacleErrorType.NOT_TAKEN:
        return "NG"
      case CrossCountryObstacleErrorType.REFUSAL:
        return "R"
      case CrossCountryObstacleErrorType.DANGEROUS:
        return "GR"
      default:
        return "?"
    }
  }
}


