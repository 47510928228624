import {defineStore} from "pinia";
import {CompetitionInfo} from "@/types/competition/Competition";
import {competitionService} from "@/service/CompetitionService";

export const useCompetitionStore = defineStore('competitionStore', {
    state: () => ({
        code: "" as string,
        competition: null as CompetitionInfo | null,
    }),
    getters: {
        getCompetition(): CompetitionInfo | null {
            return this.competition
        },
        hasCompetition(): boolean {
            return this.competition != null
        },
        hasCode(): boolean {
            return this.code != ""
        },
        getCode(): string {
            return this.code
        }
    },
    actions: {
        setCode(code: string) {
          this.code = code
        },
        setCompetition(competition: CompetitionInfo) {
            this.competition = competition
        },
        async getCompetitionRefByCode(code: string): Promise<CompetitionInfo> {
            return await competitionService.getCompetitionByCode(code)
        }
    }
})