
























































import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {HorseOnly, IndividualCombination, Practitioner, PractitionerType, Team} from "@/types/competition/Practitioner";
import HorseComponent from "@/components/HorseComponent.vue";
import {getCountryISO2} from "@/util/CountryIso";

@Component({
  components: {HorseComponent}
})
export default class PractitionerComponent extends Vue {
  @Prop() practitioner!: Practitioner
  @Prop({required: false, default: false}) outOfCompetition?: boolean;

  focus() {
    // nothing to focus on
  }

  get isHorseOnly() {
    return this.practitioner && this.practitioner.type == PractitionerType.HORSE
  }

  get isTeam() {
    return this.practitioner && this.practitioner.type == PractitionerType.TEAM
  }

  get isIndividualCombination() {
    return this.practitioner && this.practitioner.type == PractitionerType.INDIVIDUAL
  }

  asHorseOnly() {
    return this.practitioner as HorseOnly
  }

  asIndividual() {
    return this.practitioner as IndividualCombination
  }

  asTeam() {
    return this.practitioner as Team
  }

  getCountryISO2(flag: string) {
    return getCountryISO2(flag)
  }
}
