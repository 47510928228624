







import {Prop, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";

@Component({})
export default class OutOfCompetitionButton extends Vue {
  @Prop() value!: boolean

  focus() {
    (this.$refs["input"] as HTMLElement)?.focus()
  }

  onChange() {
    this.$emit("input", !this.value)
    this.$emit("changed")
  }
}
