import {
    JumpingRoundParameters,
    JumpingSportResultInput,
    ObstacleErrorResult,
    ObstacleErrorType
} from "@/types/competition/SportResult";


export const TABLEA_OBSTACLE_ERRORS = [
    {
        error: ObstacleErrorType.NO_ERROR,
        text: "0",
        variant: "success",
        errors: 0,
        additionalTime: 0,
        eliminated: false,
        refusal: false
    },
    {
        error: ObstacleErrorType.KNOCKDOWN,
        text: "4",
        variant: "danger",
        errors: 4,
        additionalTime: 0,
        eliminated: false,
        refusal: false
    },
    {
        error: ObstacleErrorType.REFUSAL,
        text: "R",
        variant: "danger",
        errors: 4,
        additionalTime: 0,
        eliminated: false,
        refusal: true
    },
    {
        error: ObstacleErrorType.BREAKDOWN,
        text: "BR",
        variant: "danger",
        errors: 4,
        additionalTime: 6,
        eliminated: false,
        refusal: true
    },
    {
        error: ObstacleErrorType.FALLEN,
        text: "VAL",
        variant: "danger",
        errors: 0,
        additionalTime: 0,
        eliminated: true,
        refusal: true
    }
]


export class TableA {
    static hasErrors(obstacle: ObstacleErrorResult): boolean {
        return TableA.hasEliminated(obstacle) || TableA.hasRefusals(obstacle) || TableA.obstacleErrorTotal(obstacle) > 0 || TableA.additionalTimeFaultTotal(obstacle) > 0;
    }

    static hasEliminated(obstacle: ObstacleErrorResult): boolean {
        return obstacle.errors.filter(f => TableA.getObstacleErrorByType(f)!.eliminated).length > 0
    }

    static countRefusals(obstacle: ObstacleErrorResult): number {
        return obstacle.errors.filter(f => TableA.getObstacleErrorByType(f)!.refusal).length
    }

    static hasRefusals(obstacle: ObstacleErrorResult): boolean {
        return this.countRefusals(obstacle) > 0;
    }

    static obstacleErrorTotal(obstacle: ObstacleErrorResult): number {
        if (!obstacle.errors || obstacle.errors.length == 0) {
            return 0;
        }
        return obstacle.errors
            .map(f => TableA.getObstacleErrorByType(f)!.errors)
            .reduce((t, s) => t + s);
    }

    static additionalTimeFaultTotal(obstacle: ObstacleErrorResult): number {
        if (!obstacle.errors || obstacle.errors.length == 0) {
            return 0;
        }
        return obstacle.errors
            .map(f => TableA.getObstacleErrorByType(f)!.additionalTime)
            .reduce((t, s) => t + s);
    }

    static getObstacleErrorByType(error: ObstacleErrorType) {
        return TABLEA_OBSTACLE_ERRORS.find(it => it.error == error)
    }

    //
    // static calculateResult(result: JumpingSportResultInput, parameters: JumpingRoundParameters) {
    //     if (result.status == TestResultStatus.FINISHED) {
    //         result.obstacleErrors = TableA.calculateObstacleErrors(result, parameters)
    //         result.timeErrors = TableA.calculateTimeErrors(result, parameters) + TableA.calculateAdditionsTimeErrors(result, parameters)
    //         // if (result.timeErrors > parameters.allowedTime) {
    //         //   result.status = TestResultStatus.ELIMINATED
    //         // }
    //     } else {
    //         result.timeErrors = 0
    //         result.obstacleErrors = 0
    //     }
    //     result.totalErrors = result.timeErrors + result.obstacleErrors
    // }

    static calculateObstacleErrors(result: JumpingSportResultInput, parameters: JumpingRoundParameters) {
        const errors = TableA.obstacles(result, parameters)
        if (errors.length == 0) {
            return 0
        }
        return errors.map(i => TableA.obstacleErrorTotal(i)).reduce((t, s) => t + s)
    }

    static calculateTimeErrors(result: JumpingSportResultInput, parameters: JumpingRoundParameters) {
        if ((result.time || 0) > (parameters.allowedTime || 0)) {
            return Math.round(((result.time || 0) - (parameters.allowedTime || 0)) + 0.5)
        } else {
            return 0
        }
    }

    static calculateAdditionsTimeErrors(result: JumpingSportResultInput, parameters: JumpingRoundParameters) {
        const errors = TableA.obstacles(result, parameters)
        if (errors.length == 0) {
            return 0
        }
        return errors.map(i => TableA.additionalTimeFaultTotal(i)).reduce((t, s) => t + s)
    }

    static obstacles(result: JumpingSportResultInput, parameters: JumpingRoundParameters) {
        return parameters.obstacles.map(po => {
                const error = result.errors.find(o => o.obstacle.order == po.order)
                if (!error) {
                    return {
                        obstacle: po,
                        errors: []
                    } as ObstacleErrorResult
                } else {
                    return error
                }
            }
        )
    }
}