





















import {Prop, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import {SportType} from "@/types/competition/Activity";
import {SportResult} from "@/types/competition/SportResult";

export enum TestResultStatus {
  NOT_STARTED = "NOT_STARTED",
  FINISHED = "FINISHED",
  DISQUALIFIED = "DISQUALIFIED",
  RETIRED = "RETIRED",
  ELIMINATED = "ELIMINATED",
  WITHDRAWN = "WITHDRAWN",
}

export const FINISH_STATUSES = [
  {
    value: TestResultStatus.NOT_STARTED,
    text: "Not Started"
  },
  {
    value: TestResultStatus.FINISHED,
    text: "Finished"
  },
  {
    value: TestResultStatus.ELIMINATED,
    text: "Eliminated"
  },
  {
    value: TestResultStatus.RETIRED,
    text: "Retired"
  },
  {
    value: TestResultStatus.DISQUALIFIED,
    text: "Disqualified"
  },
  {
    value: TestResultStatus.WITHDRAWN,
    text: "Withdrawn"
  }
]

@Component({})
export default class FinishStatusComponent extends Vue {
  @Prop() result!: SportResult
  private statuses = FINISH_STATUSES

  @Watch("result.status")
  private changed() {
    this.$emit("changed")
  }

  focus() {
    (this.$refs["input"] as HTMLElement)?.focus()
  }

  handleNumberKeys(event: any) {
    if (event.key == '0') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = TestResultStatus.NOT_STARTED
    }

    if (event.key == '1') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = TestResultStatus.FINISHED
    }

    if (event.key == '2') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = TestResultStatus.ELIMINATED
    }

    if (event.key == '3') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = TestResultStatus.RETIRED
    }

    if (event.key == '4') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = TestResultStatus.DISQUALIFIED
    }

    if (event.key == '5') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = TestResultStatus.WITHDRAWN
    }
  }

  textColor(status: TestResultStatus) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (status == TestResultStatus.FINISHED) {
      return 'text-success'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
    } else if (status == TestResultStatus.NOT_STARTED) {
      return 'text-warning'
    } else {
      return 'text-danger'
    }
  }
}
