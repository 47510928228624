


































































































































































































import {Prop, Ref, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import {
  ActivityHeader,
  CrossCountryResultHeader,
  DressageManualResultHeader,
  JumpingResultHeader,
  Obstacle,
  TableField,
  TableFieldType
} from "@/types/competition/Activity";
import {PractitionerResultData} from "@/types/competition/Practitioner";
import PractitionerComponent from "@/components/result/PractitionerComponent.vue";
import FinishStatusComponent from "@/components/result/FinishStatusComponent.vue";
import {
  CrossCountryObstacle,
  CrossCountryObstacleType,
  CrossCountrySportResultInput,
  JumpingSportResultInput,
  SportResultDataInputType,
  SportResultInput,
  TestResult
} from "@/types/competition/SportResult";
import ObstacleComponent from "@/components/result/ObstacleComponent.vue";
import PointsComponent from "@/components/result/PointsComponent.vue";
import TimeComponent from "@/components/result/shared/TimeComponent.vue";
import {createKeybindingsHandler} from "tinykeys"
import {activityService, ResultDataSaveRequest, TestResultInput, TestResultInputUtils} from "@/service/ActivityService";
import OutOfCompetitionButton from "@/components/result/OutOfCompetitionButton.vue";
import {mapStores} from "pinia";
import {useCompetitionStore} from "@/stores/Competition";
import ObstacleModal from "@/components/result/ObstacleModal.vue";
import JumpingErrorComponent from "@/components/result/JumpingErrorComponent.vue";
import MarkersComponent from "@/components/result/MarkersComponent.vue";
import MarkerModal from "@/components/result/MarkerModal.vue";
import CrossCountryObstacleModal from "@/components/result/CrossCountryObstacleModal.vue";
import CrossCountryObstacleComponent from "@/components/result/CrossCountryObstacleComponent.vue";
import CrossCountryTimeComponent from "@/components/result/CrossCountryTimeComponent.vue";

@Component({
  components: {
    CrossCountryTimeComponent,
    CrossCountryObstacleComponent,
    CrossCountryObstacleModal,
    MarkerModal,
    MarkersComponent,
    JumpingErrorComponent,
    ObstacleModal,
    OutOfCompetitionButton,
    TimeComponent, PointsComponent, ObstacleComponent, FinishStatusComponent, PractitionerComponent
  },
  computed: {
    ...mapStores(useCompetitionStore)
  }
})
export default class ResultsTable extends Vue {
  @Prop() competitionRef!: string
  @Prop() header!: ActivityHeader
  @Prop() practitioners!: Array<PractitionerResultData>
  @Ref("obstacleModal") obstacleModal!: ObstacleModal
  @Ref("crossCountryObstacleModal") crossCountryObstacleModal!: CrossCountryObstacleModal
  @Ref("markerModal") markerModal!: MarkerModal
  private tableFields: Array<any> = []
  private tableHeaderFields: Array<TableField> = []

  private handler: any

  //Controls
  private focusedRowIndex = 0
  private focusedFieldIndex = 0

  private filter = null

  private isLoading = false
  private autSaveTimeout: number | undefined = undefined
  private autoSaveTimeInMs = 5 * 60 * 1000

  private changeCount = 0

  created() {
    //this.focusCell(this.createCellId(this.focusedRowIndex, this.focusedFieldIndex))
    this.loadTable()
    this.useKeyBoardShortCuts()
    this.useAutoSave()
  }

  useAutoSave() {
    this.autSaveTimeout = setTimeout(async () => {
      await this.saveActivityData(true)
    }, this.autoSaveTimeInMs)
  }

  firstCell() {
    this.focusedFieldIndex = 0
  }

  get firstRow() {
    this.focusedRowIndex = 0
    return this.createCellId(this.focusedRowIndex, this.focusedFieldIndex)
  }

  nextCell(): string {
    this.focusedFieldIndex = this.focusedFieldIndex + 1
    return this.createCellId(this.focusedRowIndex, this.focusedFieldIndex)
  }

  previousCell(): string {
    this.focusedFieldIndex = this.focusedFieldIndex - 1
    return this.createCellId(this.focusedRowIndex, this.focusedFieldIndex)
  }

  nextRow(): string {
    this.focusedRowIndex = this.focusedRowIndex + 1
    return this.createCellId(this.focusedRowIndex, this.focusedFieldIndex)
  }

  previousRow(): string {
    this.focusedRowIndex = this.focusedRowIndex - 1
    return this.createCellId(this.focusedRowIndex, this.focusedFieldIndex)
  }

  focusCell(cellId: string) {
    const component = this.$refs[cellId] as any

    if (component != undefined) {
      // component[0].$refs[`${cellId}_field`].focus()
      component[0].focus()
    }
  }

  createCellId(rowIndex: number, fieldIndex: number): string {
    this.focusedRowIndex = rowIndex
    this.focusedFieldIndex = fieldIndex
    return `${this.practitioners[rowIndex].participationRef}_${fieldIndex}`
  }

  colSpan(testResultId: string) {
    const c = this.tableHeaderFields.filter(it => it.testResultId == testResultId).length
    if (c <= 0) {
      return 1
    } else {
      return c
    }
  }

  handleArrowRight() {
    const fieldIndex = this.focusedFieldIndex

    let id = ""
    if (fieldIndex < this.tableHeaderFields.length - 1) {
      id = this.nextCell()
    } else {
      this.handleArrowDown()
    }
    this.focusCell(id)
  }

  handleArrowLeft() {
    const fieldIndex = this.focusedFieldIndex

    let id = ""
    if (fieldIndex > 0) {
      id = this.previousCell()
    } else {
      this.handleArrowUp()
    }
    this.focusCell(id)
  }

  handleArrowDown() {
    const rowIndex = this.focusedRowIndex

    let id = ""
    if (rowIndex < this.practitioners.length - 1) {
      id = this.nextRow()
    } else {
      id = this.firstRow
    }
    this.focusCell(id)
  }

  handleArrowUp() {
    const rowIndex = this.focusedRowIndex

    let id = ""
    if (rowIndex > 0) {
      id = this.previousRow()
    } else {
      id = this.firstRow
    }
    this.focusCell(id)
  }

  fieldClicked(item: PractitionerResultData, fieldIndex: number) {
    const rowIndex = this.practitioners.indexOf(item)
    this.focusCell(this.createCellId(rowIndex, fieldIndex))
  }

  useKeyBoardShortCuts() {
    this.handler = createKeybindingsHandler({
      "ArrowRight": () => {
        this.handleArrowRight()
      },
      "ArrowLeft": () => {
        this.handleArrowLeft()
      },
      "ArrowDown": () => {
        this.handleArrowDown()
      },
      "ArrowUp": () => {
        this.handleArrowUp()
      },
      "Enter": () => {
        this.handleArrowDown()
      },
      "Tab": () => {
        this.handleArrowRight()
      }
    })
    window.addEventListener("keydown", this.handler)
  }

  beforeDestroy() {
    clearInterval(this.autSaveTimeout)
    window.removeEventListener("keydown", this.handler)
  }

  loadTable() {
    this.tableFields = [
      {
        key: 'startPosition',
        label: '#'
      },
      {
        key: 'startTime',
        label: 'Tijd'
      },
      {
        key: 'practitioner',
        label: 'Deelnemer'
      }
    ]
    this.tableHeaderFields = this.createTableFields()
    this.tableFields.push(...this.tableHeaderFields)
  }

  getRowFieldId(item: PractitionerResultData, index: number): string {
    return `${item.participationRef}_${index}`
  }

  resultForTestResultId(item: PractitionerResultData, testResultId: string): any {
    return item.results.find(testResult => testResult.testResultId == testResultId)!.input as SportResultInput
  }

  obstacleForIndexAndTestResultId(item: PractitionerResultData, field: TableField) {
    const result = item.results.find(testResult => testResult.testResultId == field.testResultId)!.input as JumpingSportResultInput
    const obstacleError = result.errors.find(error => error.obstacle.order == field.order)
    if (!obstacleError) {
      const initObstacle: Obstacle = {
        order: field.order,
        name: field.label
      }
      return initObstacle
    } else {
      return obstacleError.obstacle
    }
  }

  crossCountryObstacleForIndexAndTestResultId(item: PractitionerResultData, field: TableField) {
    const result = item.results.find(testResult => testResult.testResultId == field.testResultId)!.input as CrossCountrySportResultInput
    const obstacleError = result.errors.find(error => error.obstacle.order == field.obstacleOrder)
    if (!obstacleError) {
      const initObstacle: CrossCountryObstacle = {
        order: field.order,
        name: field.label,
        type: CrossCountryObstacleType.LOG,
        optional: false
      }
      return initObstacle
    } else {
      return obstacleError.obstacle
    }
  }

  isTableFieldType(tableFieldType: TableFieldType, tableFieldTypeToCompare: string): boolean {
    return tableFieldType == (tableFieldTypeToCompare as TableFieldType)
  }

  changed(item: PractitionerResultData) {
    item.changed = true
    this.changeCount = this.practitioners.filter(it => it.changed).length
  }

  createTableFields(): Array<TableField> {
    let fields: Array<TableField> = []
    const defaultField = {
      tdClass: 'align-middle p-0 m-0 obstacleWidth',
      thClass: 'align-middle text-center p-0 m-0 obstacleWidth'
    }
    this.header.resultHeaders.forEach(header => {
      switch (header.type) {
        case SportResultDataInputType.POINTS: {
          fields.push(
              {
                ...defaultField,
                key: `${header.order}_points`,
                field: "points",
                label: "Ptn",
                testResultId: header.testResultId,
                type: TableFieldType.POINTS,
                order: 0
              }
          )
          break;
        }
        case SportResultDataInputType.SHOW_JUMPING_MANUAL: {
          fields.push(
              {
                ...defaultField,
                key: `${header.order}_obstacleErrors`,
                field: "obstacleErrors",
                label: "SpringFtn",
                testResultId: header.testResultId,
                type: TableFieldType.JUMPING_OBSTACLE_ERRORS,
                order: 0
              },
              {
                ...defaultField,
                key: `${header.order}_timeErrors`,
                field: "timeErrors",
                label: "TijdFtn",
                testResultId: header.testResultId,
                type: TableFieldType.JUMPING_TIME_ERRORS,
                order: 1
              },
              {
                ...defaultField,
                key: `${header.order}_time`,
                field: "time",
                label: "Tijd",
                testResultId: header.testResultId,
                type: TableFieldType.TIME,
                order: 2,
              },
              {
                ...defaultField,
                key: `${header.order}_status`,
                field: "status",
                label: "Status",
                testResultId: header.testResultId,
                type: TableFieldType.STATUS,
                order: 3
              }
          )
          break;
        }
        case SportResultDataInputType.DRESSAGE_MANUAL: {
          const h = (header as DressageManualResultHeader)
          let i = 0;
          fields.push(
              {
                ...defaultField,
                key: `${header.testResultId}_points`,
                field: "points",
                label: "Ptn",
                testResultId: header.testResultId,
                type: TableFieldType.POINTS,
                order: i++
              },
          )

          if (h.markers && h.markers.length > 0) {
            fields.push(
                {
                  ...defaultField,
                  key: `${header.order}_markers_${h.testResultId}`,
                  field: "markerPoints",
                  label: "=",
                  testResultId: header.testResultId,
                  type: TableFieldType.MANUAL_MARKERS,
                  order: i++,
                  markers: h.markers
                }
            )
          }

          fields.push(
              {
                ...defaultField,
                key: `${header.testResultId}_status`,
                field: "status",
                label: "Status",
                testResultId: header.testResultId,
                type: TableFieldType.STATUS,
                order: i++
              }
          )
          break;
        }
        case SportResultDataInputType.SHOW_JUMPING: {
          let i = 0;
          (header as JumpingResultHeader).obstacles.forEach(o =>
              fields.push(
                  {
                    ...defaultField,
                    key: `${header.order}_obstacle_${i}`,
                    field: "obstacle",
                    label: o,
                    testResultId: header.testResultId,
                    type: TableFieldType.OBSTACLE,
                    order: i++,
                  }
              )
          )
          fields.push(
              {
                ...defaultField,
                key: `${header.order}_time`,
                field: "time",
                label: "Tijd",
                testResultId: header.testResultId,
                type: TableFieldType.TIME,
                order: i++,
              },
              {
                ...defaultField,
                key: `${header.order}_status`,
                field: "status",
                label: "Status",
                testResultId: header.testResultId,
                type: TableFieldType.STATUS,
                order: i++
              }
          )
          break;
        }
        case SportResultDataInputType.STYLE_RIDING_MANUAL: {
          fields.push(
              {
                ...defaultField,
                key: `${header.order}_points`,
                field: "points",
                label: "Ptn",
                testResultId: header.testResultId,
                type: TableFieldType.POINTS,
                order: 0
              },
              {
                ...defaultField,
                key: `${header.order}_status`,
                field: "status",
                label: "Status",
                testResultId: header.testResultId,
                type: TableFieldType.STATUS,
                order: 1
              }
          )
          break;
        }
        case SportResultDataInputType.CROSS_COUNTRY: {
          let i = 0;
          (header as CrossCountryResultHeader).obstacles.forEach(o =>
              fields.push(
                  {
                    ...defaultField,
                    key: `${header.order}_obstacle_${i}`,
                    field: "obstacle",
                    label: o.name,
                    testResultId: header.testResultId,
                    type: TableFieldType.CROSS_COUNTRY_OBSTACLE,
                    obstacleErrorSpecifications: (header as CrossCountryResultHeader).obstacleErrorSpecifications,
                    obstacles: (header as CrossCountryResultHeader).obstacles,
                    obstacleOrder: o.order,
                    order: i++,
                  }
              )
          )
          fields.push(
              {
                ...defaultField,
                key: `${header.order}_time`,
                field: "time",
                label: "Tijd",
                testResultId: header.testResultId,
                type: TableFieldType.CROSS_COUNTRY_TIME,
                order: i++,
              },
              {
                ...defaultField,
                key: `${header.order}_status`,
                field: "status",
                label: "Status",
                testResultId: header.testResultId,
                type: TableFieldType.STATUS,
                order: i++
              }
          )
          break;
        }
      }
    })

    fields.push({
      key: 'outOfCompetition',
      type: TableFieldType.OUT_OF_COMPETITION,
      order: fields.length,
      testResultId: "null",
      field: "outOfCompetition",
      label: "BW"
    })

    return fields
  }

  private toSportResultInput(result: TestResult): TestResultInput {
    return TestResultInputUtils.toTestResultInput(result)
  }

  reloadActivityData() {
    this.$bvModal.msgBoxConfirm("Bent u zeker dat u wilt vernieuwen? Niet opgeslagen resultaten zullen verloren gaan", {
      okTitle: 'Opnieuw laden',
      cancelTitle: 'Annuleren'
    }).then(r => {
      if (r) {
        this.$emit("reloadData")
      }
    })
  }

  saveActivityData(isAutoSave: boolean) {
    const p = this.practitioners.filter(it => it.changed)
    if (!p || p.length <= 0) {
      if (isAutoSave == true) {
        this.useAutoSave()
      }
      return
    }

    if (isAutoSave == true) {
      this._doSave(p)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Automatisch opgeslagen',
              text: 'Resultaten zijn opgeslagen'
            })
            this.resetChanged()
          })
          .finally(() => {
            this.useAutoSave()
          })
    } else {
      this.isLoading = true
      this._doSave(p)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Opgeslagen!',
              text: 'Resultaten zijn opgeslagen'
            });
          })
          .catch(e => this.$notify({
            type: 'error',
            title: 'Opslaan mislukt',
            text: 'Oops, er ging iets fout tijdens het opslaan'
          }))
          .finally(() => {
            this.isLoading = false
            this.useAutoSave()
            this.$emit("reloadData")
          })
    }
  }

  private resetChanged() {
    this.practitioners.forEach(it => it.changed = false)
    this.changeCount = 0
  }

  private async _doSave(changed: Array<PractitionerResultData>): Promise<void> {
    const competitionStore = useCompetitionStore()
    return activityService.saveActivityData(
        competitionStore.getCode,
        this.competitionRef,
        this.header.startlistRef,
        changed.map((data) => {
          const result: ResultDataSaveRequest = {
            participationRef: data.participationRef,
            outOfCompetition: data.outOfCompetition,
            results: data.results.map(r => this.toSportResultInput(r))
          }
          return result
        })
    )
  }
}
