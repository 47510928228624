






















import {Ref, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {Obstacle} from "@/types/competition/Activity";
import {JumpingSportResultInput, ObstacleErrorResult, ObstacleErrorType} from "@/types/competition/SportResult";
import {TableA, TABLEA_OBSTACLE_ERRORS} from "@/data/TableA";
import {BModal} from "bootstrap-vue";

@Component
export default class ObstacleModal extends Vue {
  private obstacle: Obstacle = {} as Obstacle
  private result: JumpingSportResultInput = {} as JumpingSportResultInput
  private visible = false
  @Ref("obstacleModal") modal!: BModal

  show(result: JumpingSportResultInput, obstacle: Obstacle) {
    this.result = result
    this.obstacle = obstacle
    this.modal.show()
  }

  get possibleErrors() {
    return TABLEA_OBSTACLE_ERRORS
  }

  getObstacleErrorByType(error: ObstacleErrorType) {
    return TableA.getObstacleErrorByType(error)
  }

  getObstacleResult(obstacle: Obstacle) {
    let o = this.result.errors.find(it => it.obstacle.order == obstacle.order)
    if (!o) {
      o = {
        obstacle: obstacle,
        errors: []
      } as ObstacleErrorResult
      this.result.errors.push(o)
      return o
    } else {
      return o
    }
  }


  addError(obstacle: ObstacleErrorResult, error: ObstacleErrorType) {
    if (error == ObstacleErrorType.NO_ERROR) {
      obstacle.errors.splice(0, obstacle.errors.length)
    } else {
      obstacle.errors.push(error)
    }
    this.updateErrors()
  }

  updateErrors() {
    // TableA.calculateResult(this.value, this.parameters)
  }

  removeError(obstacle: ObstacleErrorResult, index: number) {
    obstacle.errors.splice(index, 1)
    this.updateErrors()
  }
}
