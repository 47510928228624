var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.size,
    _vm.controlsType === 'updown' ? 'updown' : ''
  ],style:(_vm.widthStyle)},[_c('input',_vm._g(_vm._b({ref:"input",class:_vm.inputClasses,style:(_vm.inputStyle),attrs:{"name":_vm.name,"type":"number","step":"any","placeholder":_vm.placeholder,"max":_vm.max,"min":_vm.min,"autofocus":_vm.autofocus,"disabled":_vm.disabled,"readonly":_vm.readonly || !_vm.isInput},domProps:{"value":_vm.computedValue},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }return $event.preventDefault()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return $event.preventDefault()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return $event.preventDefault()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return $event.preventDefault()}],"input":_vm.onInput,"change":_vm.onChange,"blur":_vm.onBlur,"focus":_vm.onFocus}},'input',_vm.$attrs,false),
      _vm.mousewheel
        ? { wheel: _vm.throttle(_vm.mouseWheelHandler, 6000) }
        : {
            wheel: function(evt) {
              evt.preventDefault();
            }
          }
    )),(_vm.controls)?_c('button',{staticClass:"input-btn btn-decrement",attrs:{"type":"button","disabled":_vm.disabled || _vm.minDisable},on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.start(_vm.decrement)},"touchstart":function($event){$event.preventDefault();return _vm.start(_vm.decrement)},"touchend":function($event){$event.preventDefault();return _vm.stop.apply(null, arguments)}}},[_c('span',{staticClass:"btn-icon"})]):_vm._e(),(_vm.controls)?_c('button',{staticClass:"input-btn btn-increment",attrs:{"type":"button","disabled":_vm.disabled || _vm.maxDisable},on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.start(_vm.increment)},"touchstart":function($event){$event.preventDefault();return _vm.start(_vm.increment)},"touchend":function($event){$event.preventDefault();return _vm.stop.apply(null, arguments)}}},[_c('span',{staticClass:"btn-icon"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }