import {defineStore} from "pinia";
import {RankingResponse, rankingService} from "@/service/RankingService";
import {useCompetitionStore} from "@/stores/Competition";

export const useSelectedRankingStore = defineStore('selectedRankingStore', {
    state: () => ({
        rankingRef: "" as string
    }),
    getters: {
        getRankingRef(): string {
            return this.rankingRef
        },
        hasSelected(): boolean {
            return this.rankingRef != ""
        }
    },
    actions: {
        setRankingRef(rankingRef: string) {
            this.rankingRef = rankingRef
        },
        async loadRanking(): Promise<RankingResponse> {
            return await rankingService.getRanking(useCompetitionStore().code, useCompetitionStore().competition!.id, this.rankingRef)
        }
    }
})