




































import {Vue} from "vue-property-decorator";
import Component from "vue-class-component";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import VueFakeInput from 'vue-fake-input';
import {useCompetitionStore} from "@/stores/Competition";
import {mapStores} from "pinia";

@Component({
  components: {
    'vue-fake-input': VueFakeInput
  },
  computed: {
    ...mapStores(useCompetitionStore)
  }
})
export default class AuthView extends Vue {
  public code = ""
  private codeIsInvalid = false

  created() {
    this.codeIsInvalid = false
    this.code = ""
  }

  mounted() {
    const code = this.$route.query['code']?.toString()
    if (code) {
      this.openCompetition(code)
    }
  }

  goToCompetition() {
    if (this.code !== "") {
      this.openCompetition(btoa(this.code))
    }
  }

  openCompetition(code: string) {
    const competitionStore = useCompetitionStore()
    competitionStore.setCode(code)
    competitionStore.getCompetitionRefByCode(code)
        .then((r: any) => {
          localStorage.setItem("competition_code", code)
          competitionStore.setCompetition(r.data)
          this.$router.push('/competition/' + r.data.id)
        })
        .catch(() => {
          this.code = ""
          this.codeIsInvalid = true
        })
  }
}
