import {RouteConfig} from "vue-router";
import CompetitionLayout from "@/views/competition/CompetitionLayout.vue";
import CompetitionView from "@/views/competition/CompetitionView.vue";

const routes: Array<RouteConfig> = [
    {
        path: '/competition/:competition',
        name: 'competition',
        component: CompetitionLayout,
        children: [
            {
                path: 'activity/:activity',
                component: CompetitionView
            }
        ]
    }
]

export default {
    routes: routes
};