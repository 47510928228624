

















import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {SportResult, SportResultStatus} from "@/types/competition/SportResult";

@Component({})
export default class PointsComponent extends Vue {
  @Prop() result!: SportResult
  @Prop({default: 'totalPoints'}) field!: string
  @Prop() practitionerRef!: string

  focus() {
    (this.$refs["input"] as HTMLInputElement)?.select()
  }

  mounted() {
    this.$watch(`result.${this.field}`, this.onValueChange)
  }

  onValueChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (this.result[this.field] > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = SportResultStatus.FINISHED
    }
    this.$emit("changed")
  }

  isDisabled() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return (this.result.status !== SportResultStatus.NOT_STARTED && this.result.status !== SportResultStatus.FINISHED)
  }
}
