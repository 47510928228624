




















import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {Obstacle} from "@/types/competition/Activity";
import {
  JumpingSportResult, JumpingSportResultInput,
  ObstacleErrorResult,
  ObstacleErrorType,
  SportResultStatus
} from "@/types/competition/SportResult";
import {TableA, TABLEA_OBSTACLE_ERRORS} from "@/data/TableA";
import ObstacleModal from "@/components/result/ObstacleModal.vue";

@Component({})
export default class ObstacleComponent extends Vue {
  @Prop() componentRef!: string
  @Prop() obstacle!: Obstacle
  @Prop() result!: JumpingSportResultInput
  @Prop() index!: number
  @Prop() obstacleModal!: ObstacleModal

  private showPopover = false

  private typeIndex = 0

  focus() {
    (this.$refs["input"] as HTMLElement)?.focus()
  }

  get possibleErrors() {
    return TABLEA_OBSTACLE_ERRORS
  }

  popoverTrigger() {
    this.obstacleModal.show(this.result, this.obstacle)
  }

  handleEnter(event: any) {
    event.preventDefault()
  }

  summary(obstacle: ObstacleErrorResult) {
    if (TableA.hasEliminated(obstacle)) {
      return "E";
    } else if (obstacle.errors.length == 1) {
      return this.getObstacleErrorByType(obstacle.errors[0])!.text.slice(0, 1);
    } else {
      return "" + TableA.obstacleErrorTotal(obstacle)
    }
  }

  summaryVariant(obstacle: ObstacleErrorResult): string {
    if (this.isDisabled()) {
      return 'light'
    } else {
      return TableA.hasErrors(obstacle) ? "danger" : "success"
    }
  }

  getObstacleErrorByType(error: ObstacleErrorType) {
    return TableA.getObstacleErrorByType(error)
  }

  getObstacleResult(obstacle: Obstacle) {
    let o = this.result.errors.find(it => it.obstacle.order == obstacle.order)
    if (!o) {
      o = {
        obstacle: obstacle,
        errors: []
      } as ObstacleErrorResult
      this.result.errors.push(o)
      return o
    } else {
      return o
    }
  }

  triggerPopover() {
    this.showPopover = false
  }

  handleNumberKeys(event: any) {
    const obstacle = this.getObstacleResult(this.obstacle)
    if (event.key == '0') {
      obstacle.errors = [this.possibleErrors[0].error]
    }

    if (event.key == '1') {
      obstacle.errors = [this.possibleErrors[1].error]
    }

    if (event.key == '2') {
      obstacle.errors = [this.possibleErrors[2].error]
    }

    if (event.key == '3') {
      obstacle.errors = [this.possibleErrors[3].error]
    }

    if (event.key == '4') {
      obstacle.errors = [this.possibleErrors[4].error]
    }
  }

  handlePlusMinusKey(event: any) {
    if (event.keyCode == 43) {
      this.addNextError(this.getObstacleResult(this.obstacle))
    }

    if (event.keyCode == 45) {
      this.addPreviousError(this.getObstacleResult(this.obstacle))
    }

    this.handleNumberKeys(event)
  }

  addNextError(obstacle: ObstacleErrorResult) {
    if (obstacle.errors.length > 0 && this.typeIndex < this.possibleErrors.length) {
      let error = this.getObstacleErrorByType(obstacle.errors[0])
      this.typeIndex = this.possibleErrors.indexOf(error!);
    }

    if (this.typeIndex < this.possibleErrors.length - 1) {
      this.typeIndex++
      obstacle.errors = [this.possibleErrors[this.typeIndex].error]
    }
  }

  addPreviousError(obstacle: ObstacleErrorResult) {
    if (obstacle.errors.length > 0) {
      let error = this.getObstacleErrorByType(obstacle.errors[0])
      this.typeIndex = this.possibleErrors.indexOf(error!);
    }

    if (this.typeIndex > 0) {
      this.typeIndex--
      obstacle.errors = [this.possibleErrors[this.typeIndex].error]
    }
  }

  addError(obstacle: ObstacleErrorResult, error: ObstacleErrorType) {
    if (error == ObstacleErrorType.NO_ERROR) {
      obstacle.errors.splice(0, obstacle.errors.length)
    } else {
      obstacle.errors.push(error)
    }
    this.updateErrors()
  }

  updateErrors() {
    // TableA.calculateResult(this.value, this.parameters)
  }

  removeError(obstacle: ObstacleErrorResult, index: number) {
    obstacle.errors.splice(index, 1)
    this.updateErrors()
  }

  isDisabled() {
    return (this.result.status !== SportResultStatus.NOT_STARTED && this.result.status !== SportResultStatus.FINISHED)
  }
}
