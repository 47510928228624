















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {BindingMenuEntry} from "@/service/ActivityService";
import {mixins} from "vue-class-component";
import MenuComponentMixin from "@/components/MenuComponentMixin";
import StartlistGroupMenuComponent from "@/components/StartlistGroupMenuComponent.vue";
@Component({
  components: {StartlistGroupMenuComponent}
})
export default class BindingMenuComponent extends mixins(MenuComponentMixin, Vue) {
  @Prop({required: true}) competitionRef!: string
  @Prop({required: true}) binding!: BindingMenuEntry

  get hasOnlyOneStartlistAndOneSet() {
    return this.binding
        && this.binding.startlists && this.binding.startlists.length == 1
        && this.binding.startlists[0].startlists && this.binding.startlists[0].startlists.length == 1
  }

  get startlist() {
    return this.binding.startlists[0].startlists[0]
  }

}
