


















































import {Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import {mapStores} from "pinia";
import {activityService} from "@/service/ActivityService";
import {ActivityHeader} from "@/types/competition/Activity";
import ResultsTable from "@/components/result/shared/ResultsTable.vue";
import {PractitionerResultData} from "@/types/competition/Practitioner";
import {useCompetitionStore} from "@/stores/Competition";
import {useSelectedRankingStore} from "@/stores/Ranking";

@Component({
  components: {
    ResultsTable,
  },
  computed: {
    ...mapStores(useCompetitionStore)
  }
})
export default class CompetitionView extends Vue {
  private header: ActivityHeader = {} as ActivityHeader
  private practitioners: Array<PractitionerResultData> = []
  private competitionRef = ""
  private isLoading = true

  @Watch("$route")
  onRouteChange() {
    this.loadData()
  }

  created() {
    this.loadData()
  }

  async loadData() {
    this.practitioners = []
    this.isLoading = true

    const activityRef = this.$router.currentRoute.params.activity
    this.competitionRef = this.$router.currentRoute.params.competition
    const competitionStore = useCompetitionStore()
    const {
      header,
      practitioners
    } = (await activityService.getActivityData(competitionStore.getCode, this.competitionRef, activityRef)).data
    this.header = header
    this.practitioners = practitioners

    setTimeout(() => {
      this.isLoading = false
    }, 0)
  }

  showRanking(rankingRef: string) {
    useSelectedRankingStore().setRankingRef(rankingRef)
    this.$bvModal.show('rankingModal')
  }
}
