



































import {Component, Prop, Vue} from 'vue-property-decorator';
import {BindingMenuEntry, StartlistGroupMenuData, StartlistMenudata} from "@/service/ActivityService";
import {mixins} from "vue-class-component";
import MenuComponentMixin from "@/components/MenuComponentMixin";

@Component
export default class StartlistGroupMenuComponent extends mixins(MenuComponentMixin, Vue) {
  @Prop({required: true}) competitionRef!: string
  @Prop({required: true}) startlistGroup!: StartlistGroupMenuData

  get hasOnlyOneSet() {
    return this.startlistGroup
        && this.startlistGroup.startlists && this.startlistGroup.startlists.length == 1
  }

  get startlist() {
    return this.startlistGroup.startlists[0]
  }

}
