





















































import {Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import CompetitionHeader from "@/views/competition/CompetitionHeader.vue";
import CompetitionSideBar from "@/views/competition/CompetitionSideBar.vue";
import {useCompetitionStore} from "@/stores/Competition";
import {mapStores} from "pinia";
import {CompetitionInfo, initCompetitionInfo} from "@/types/competition/Competition";
import {activityService, Menu} from "@/service/ActivityService";
import RankingModal from "@/components/RankingModal.vue";
import ConfirmRefreshModal from "@/components/ConfirmRefreshModal.vue";

@Component({
  components: {ConfirmRefreshModal, RankingModal, CompetitionSideBar, CompetitionHeader},
  computed: {
    ...mapStores(useCompetitionStore)
  }
})
export default class CompetitionLayout extends Vue {
  public menu: Menu = {entries: []}
  public competition: CompetitionInfo = initCompetitionInfo()
  private hideSidebar = false
  private hasRouterContent = false

  private activitiesLoading = false

  created() {
    this.loadData()
    this.hasRouterContent = this.$router.currentRoute.params.activity != undefined
  }

  @Watch("$route")
  onRouteChange() {
    this.hasRouterContent = this.$router.currentRoute.params.activity != undefined
  }

  async loadData() {
    this.activitiesLoading = true
    const competitionStore = useCompetitionStore()
    const r: any = await competitionStore.getCompetitionRefByCode(competitionStore.getCode)
    this.competition = r.data
    competitionStore.setCompetition(r.data)

    const competitionRef = this.$router.currentRoute.params.competition
    const response: any = await activityService.getMenu(competitionStore.getCode, competitionRef)
    this.menu = response.data
    this.activitiesLoading = false
  }

  changeCompetition() {
    localStorage.clear()
    window.location.reload();
  }
}
