










































import {Ref, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {CrossCountryObstacle, CrossCountrySportResultInput} from "@/types/competition/SportResult";
import {BModal} from "bootstrap-vue";
import {
  CrossCountryObstacleErrorSpecificationUi,
  CrossCountryPenaltiesHelper
} from "@/components/result/CrossCountryPenaltiesHelper";
import {CrossCountryObstacleErrorSpecification} from "@/types/competition/Activity";
import {PractitionerResultData} from "@/types/competition/Practitioner";

@Component
export default class CrossCountryObstacleModal extends Vue {
  private data: PractitionerResultData = {} as PractitionerResultData
  private obstacle: CrossCountryObstacle = {} as CrossCountryObstacle
  private obstacles: Array<CrossCountryObstacle> = []
  private helper!: CrossCountryPenaltiesHelper
  private shown = false
  @Ref("obstacleModal") modal!: BModal

  show(data: PractitionerResultData, result: CrossCountrySportResultInput, obstacle: CrossCountryObstacle, obstacleErrorSpecifications: Array<CrossCountryObstacleErrorSpecificationUi>, obstacles: Array<CrossCountryObstacle>) {
    this.data = data
    this.obstacle = obstacle
    this.obstacles = obstacles

    this.helper = new CrossCountryPenaltiesHelper(obstacleErrorSpecifications, result)
    this.modal.show()
  }

  tapObstacle(specification: CrossCountryObstacleErrorSpecification, obstacle: CrossCountryObstacle) {
    this.helper.tapObstacle(specification, obstacle)
    this.$emit("changed", this.data)
  }

}
