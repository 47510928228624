






import {Component, Prop, Vue} from 'vue-property-decorator';
import {SportResultStatus} from "@/types/competition/SportResult";


@Component
export default class TestResultStatusComponent extends Vue {
  @Prop({required: false, default: "NOT_STARTED"}) value!: SportResultStatus

  variant(status: SportResultStatus) {
    switch (status) {
      case SportResultStatus.DISQUALIFIED:
      case SportResultStatus.WITHDRAWN:
      case SportResultStatus.RETIRED:
      case SportResultStatus.ELIMINATED:
        return "danger"
      case SportResultStatus.FINISHED:
        return "success"
      case SportResultStatus.INCOMPLETE:
        return "warning"
      default:
        return "info"
    }
  }

  label(status: SportResultStatus) {
    switch (status) {
      case SportResultStatus.DISQUALIFIED:
        return "Disqualified"
      case SportResultStatus.WITHDRAWN:
        return "Withdrawn"
      case SportResultStatus.RETIRED:
        return "Retired"
      case SportResultStatus.ELIMINATED:
        return "Eliminated"
      case SportResultStatus.FINISHED:
        return "Finished"
      case SportResultStatus.NOT_STARTED:
        return "Not Started"
      case SportResultStatus.INCOMPLETE:
        return "Incomplete"
      default:
        return "???"
    }
  }
}
