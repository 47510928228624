



















import {Prop, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import {CrossCountrySportResultInput, SportResultStatus} from "@/types/competition/SportResult";
import Cleave from 'vue-cleave-component';

@Component({
  components: {Cleave}
})
export default class CrossCountryTimeComponent extends Vue {
  @Prop() result!: CrossCountrySportResultInput

  focus() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    (this.$refs["input"] as HTMLElement)?.$el.select()
  }

  get time() {
    if (this.result.time) {
      return this.$moment(this.$moment.duration(this.result.time, "milliseconds").as('milliseconds')).format('mm:ss')
    } else {
      return undefined
    }
  }

  set time(value: string | undefined) {
    if (value && value.length == 5) {
      this.result.time = this.$moment(value, "mm:ss").diff(this.$moment().startOf('day'), 'milliseconds')
    }
  }


  @Watch("result.time")
  onTimeChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (this.result.time > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = SportResultStatus.FINISHED
    }
    this.$emit("changed")
  }

  isDisabled() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return (this.result.status !== SportResultStatus.NOT_STARTED && this.result.status !== SportResultStatus.FINISHED)
  }
}
