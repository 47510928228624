import {Component, Vue} from "vue-property-decorator";
import {Activity} from "@/types/competition/Activity";
import {useStateStore} from "@/stores/State";

@Component
export default class MenuComponentMixin extends Vue{
    isActive(ref: string) {
        return ref == this.$route.params.activity
    }

    goToRoute(url: string, activity: Activity) {
        const stateStore = useStateStore()
        stateStore.setSelectedActivity(activity)
        this.$router.push(url)
    }
}