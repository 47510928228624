
















import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {JumpingSportResultInput, SportResultStatus} from "@/types/competition/SportResult";
import VueNumericInput from "@/components/vue-numeric-input.vue";

@Component({
  components: {VueNumericInput}
})
export default class JumpingErrorComponent extends Vue {
  @Prop() result!: JumpingSportResultInput
  @Prop() practitionerRef!: string
  @Prop() field!: string

  focus() {
    (this.$refs["input"] as HTMLInputElement)?.select()
  }

  mounted() {
    this.$watch(`result.${this.field}`, this.onTimeChange)
  }

  onTimeChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (this.result[this.field] > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.result.status = SportResultStatus.FINISHED
    }
    this.$emit("changed")
  }

  isDisabled() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return (this.result.status !== SportResultStatus.NOT_STARTED && this.result.status !== SportResultStatus.FINISHED)
  }
}
