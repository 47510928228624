import {RefWithName} from "@/types/competition/General";
import {PersonName} from "@/types/competition/Practitioner";

export interface TestResult {
    testResultId: string;
    result: SportResult;
    input: SportResultInput;
}

export enum SportResultStatus {
    NOT_STARTED = "NOT_STARTED",
    FINISHED = "FINISHED",
    DISQUALIFIED = "DISQUALIFIED",
    RETIRED = "RETIRED",
    ELIMINATED = "ELIMINATED",
    WITHDRAWN = "WITHDRAWN",
    INCOMPLETE = "INCOMPLETE",
}

export const FINISH_STATUSES = [
    {
        value: SportResultStatus.NOT_STARTED,
        text: "Not Started"
    },
    {
        value: SportResultStatus.FINISHED,
        text: "Finished"
    },
    {
        value: SportResultStatus.ELIMINATED,
        text: "Eliminated"
    },
    {
        value: SportResultStatus.RETIRED,
        text: "Retired"
    },
    {
        value: SportResultStatus.DISQUALIFIED,
        text: "Disqualified"
    },
    {
        value: SportResultStatus.WITHDRAWN,
        text: "Withdrawn"
    }
]

export enum SportResultDataType {
    POINTS = "POINTS",
    SHOW_JUMPING = "SHOW_JUMPING",
    DRESSAGE = "DRESSAGE",
    STYLE_RIDING = "STYLE_RIDING",
    PRESENTATION = "PRESENTATION"
}

export enum SportResultDataInputType {
    POINTS = "POINTS",
    SHOW_JUMPING = "SHOW_JUMPING",
    SHOW_JUMPING_MANUAL = "SHOW_JUMPING_MANUAL",
    //DRESSAGE = "DRESSAGE",
    DRESSAGE_MANUAL = "DRESSAGE_MANUAL",
    //STYLE_RIDING = "STYLE_RIDING",
    STYLE_RIDING_MANUAL = "STYLE_RIDING_MANUAL",
    PRESENTATION = "PRESENTATION",
    CROSS_COUNTRY = "CROSS_COUNTRY"
}

export interface SportResult {
    type: SportResultDataType;
}

export interface SportResultInput {
    type: SportResultDataInputType;
}

// DRESSAGE
export interface DressageSportResult extends SportResult {
    status: SportResultStatus;
    totalPoints?: number;
    totalPercentage?: number;
}

export interface DressageSportResultManualInput extends SportResultInput {
    status: SportResultStatus;
    points?: number;
    markerPoints?: Array<DressageScoreResultInput>;
}

export interface DressageScoreResultInput {
    id: string;
    value: number;
    remarks?: string;
}

// PRESENTATION
export interface PresentationSportResult extends SportResult {
    status: SportResultStatus;
    total?: number;
    totalPercentage?: number;
    markers?: Array<PresentationMarkerResult>;
}

export interface PresentationMarkerResult {
    protocolMarkerUuid: string;
    value?: number;
    remarks?: string;
}

// STYLERIDING
export interface StyleRidingSportResult extends SportResult {
    status: SportResultStatus;
    points?: number;
    stylePoints?: number;
    jumpingObstacleErrors?: number;
    jumpingTimeErrors?: number;
}

export interface StyleRidingSportResultInput extends SportResultInput {
    status: SportResultStatus;
    points?: number;
}

// JUMPING
export interface JumpingSportResult extends SportResult {
    status: SportResultStatus;
    time?: number;
    timeSec?: number;
    obstacleErrors?: number;
    timeErrors?: number;
    totalErrors?: number;
    errors: Array<ObstacleErrorResult>;
}

export interface JumpingSportResultInput extends SportResultInput {
    status: SportResultStatus;
    time?: number;
    errors: Array<ObstacleErrorResult>;
}

export interface JumpingSportResultManualInput extends SportResultInput {
    status: SportResultStatus;
    time?: number;
    timeErrors?: number;
    obstacleErrors?: number;
}

export interface ObstacleErrorResult {
    obstacle: Obstacle;
    errors: Array<ObstacleErrorType>;
}

export interface Obstacle {
    order: number;
    name: string;
}

export enum ObstacleErrorType {
    NO_ERROR = 'NO_ERROR',
    KNOCKDOWN = 'KNOCKDOWN',
    REFUSAL = 'REFUSAL',
    BREAKDOWN = 'BREAKDOWN',
    RETIRED = 'RETIRED',
    FALLEN = 'FALLEN',
    GAVE_UP = 'GAVE_UP'
}

export interface JumpingRoundParameters {
    round: number;
    protocol: RefWithName,
    penaltyTable: JumpingPenaltyTable,
    courseDesigner?: PersonName
    timingResponsible?: PersonName
    jury?: PersonName
    courseDesignUrl?: string,
    allowedTime?: number
    timeLimit?: number
    countdownTime?: number,
    obstacles: Array<JumpingObstacle>
}

export interface JumpingObstacle {
    order: number,
    name: string
}

export enum JumpingPenaltyTable {
    TABLE_A = "TABLE_A",
    TABLE_C = "TABLE_C",
    IDEAL_TIME = "IDEAL_TIME",
}

// CROSSCOUNTRY
export interface CrossCountrySportResult extends SportResult {
    status: SportResultStatus;
    time?: number;
    obstacleErrorTotal?: number;
    timeErrorTotal?: number;
    totalErrors?: number;
}

export interface CrossCountrySportResultInput extends SportResultInput {
    status: SportResultStatus;
    time?: number;
    errors: Array<CrossCountryObstacleErrorResult>;
}

export interface CrossCountryObstacleErrorResult {
    obstacle: CrossCountryObstacle;
    errors: Array<CrossCountryObstacleErrorType>;
}

export interface CrossCountryObstacle {
    order: number,
    name: string,
    optional: boolean,
    type: CrossCountryObstacleType,
}

export enum CrossCountryObstacleType {
    LOG = "LOG",
    CORNER = "CORNER",
    ANGLED = "ANGLED",
    BOUNCE = "BOUNCE",
    GATE = "GATE",
    WALL = "WALL",
    COFIN = "COFIN",
    WATER = "WATER",
    PALISADE = "PALISADE",
    STEPS = "STEPS",
    BRUSH = "BRUSH",
    TYRE = "TYRE",
    ROLL_TOP = "ROLL_TOP",
    TABLE = "TABLE",
    TRAKEHNER = "TRAKEHNER",
    ARROWHEAD = "ARROWHEAD",
    STILE = "STILE"
}

export enum CrossCountryObstacleErrorType {
    NO_ERROR = "NO_ERROR",
    REFUSAL = "REFUSAL",
    SAFETY_FLAP = "SAFETY_FLAP",
    DANGEROUS = "DANGEROUS",
    FALL_RIDER = "FALL_RIDER",
    FALL_HORSE = "FALL_HORSE",
    NOT_TAKEN = "NOT_TAKEN",
    FLAGGED_OBSTACLE = "FLAGGED_OBSTACLE"
}