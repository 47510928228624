import Vue from 'vue'
import App from './App.vue'
import router from './router'

/**
 *  Bootstrap
 */

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "./scss/index.scss"
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import VueHtmlToPaper from 'vue-html-to-paper';

/**
 *  Font Awesome
 */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/**
 * Pinia
 */
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi);

import { createPinia, setMapStoreSuffix } from 'pinia'

setMapStoreSuffix('') // completely remove the suffix
export const pinia = createPinia()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Vue.use(pinia)

declare module 'pinia' {
  export interface MapStoresCustomization {
    // set it to the same value as above
    suffix: ''
  }
}

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueFlags from "@growthbunker/vueflags";

Vue.use(VueFlags, {
  // Specify the path of the folder where the flags are stored.
  iconPath: '/img/flags/',
});

//axios
import axios from 'axios'
import VueAxios from 'vue-axios'
import errorResponseHandler from "@/axios";
Vue.use(VueAxios, axios)
require('./axios')

import Notifications from 'vue-notification'
Vue.use(Notifications)

//printing
const options = {
  name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    '/css/print.css'
  ],
  timeout: 10, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

import VueMoment from "vue-moment";
Vue.use(VueMoment)

declare module 'vue/types/vue' {
    interface Vue {
        $htmlToPaper: any;
    }
}


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
