






import {Component, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import TestResultStatusComponent from "@/components/result/field/TestResultStatusComponent.vue";
import TestResultMixin from "@/components/result/field/TestResultMixin.vue";
import {PercentageField} from "@/types/competition/Ranking";

@Component({
  components: {TestResultStatusComponent}
})
export default class PercentageResultComponent extends mixins(Vue, TestResultMixin) {
  @Prop({required: true}) result!: PercentageField
}
