import axios from "axios";
import {CompetitionRankingEntry, RankingFieldHeader} from "@/types/competition/Ranking";
import {RefWithName} from "@/types/competition/General";

class RankingService {
    public getRanking(code: string, competitionRef: string, rankingRef: string): Promise<RankingResponse> {
        return new Promise<RankingResponse>((resolve, reject) => {
            axios.get(`/api/fastin/competition/${competitionRef}/ranking/${rankingRef}`, {
                headers: {
                    'code': code
                }
            })
                .then(r => resolve(r.data))
                .catch(e => reject(e))
        })
    }
}

export interface RankingResponse {
    test: RefWithName
    testSet: RefWithName
    ref: string,
    name: string,
    // sportTypeVariant: SportTypeVariant,
    // sportCategory: SportCategory,
    career?: RefWithName,
    championship?: RefWithName,
    protocol: RefWithName,
    headers: Array<RankingFieldHeader>,
    entries: Array<CompetitionRankingEntry>
}

export const rankingService = new RankingService()