







import {Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import FastInNavbar from "@/components/FastInNavbar.vue";

@Component({
  components: {FastInNavbar}
})
export default class FastInLayout extends Vue {
}
