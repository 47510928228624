import {ActivityHeader} from "@/types/competition/Activity";
import axios from "axios";
import {PractitionerResultData} from "@/types/competition/Practitioner";
import {SportResultInput, TestResult} from "@/types/competition/SportResult";

class ActivityService {
    public getMenu(code: string, competitionRef: string): Promise<Menu> {
        return axios.get(`/api/fastin/competition/${competitionRef}/activity`, {
            headers: {
                'code': code
            }
        })
    }

    public getActivityData(code: string, competitionRef: string, activityRef: string): Promise<any> {
        return axios.get(`/api/fastin/competition/${competitionRef}/activity/${activityRef}`, {
            headers: {
                'code': code
            }
        })
    }

    public saveActivityData(code: string, competitionRef: string, activityRef: string, data: Array<ResultDataSaveRequest>): Promise<void> {
        return axios.post(`/api/fastin/competition/${competitionRef}/activity/${activityRef}`, {
            practitionerResults: data
        }, {
            headers: {
                'code': code
            }
        })
    }
}

export interface Menu {
    entries: Array<SportTypeVariantMenuEntry>
}

export interface SportTypeVariantMenuEntry {
    name: string,
    entries: Array<BindingMenuEntry>
}

export interface BindingMenuEntry {
    name: string,
    hasMultipleStartlists: boolean,
    startlists: Array<StartlistGroupMenuData>
}


export interface StartlistGroupMenuData {
    name: string,
    hasMultipleStartlists: boolean,
    startlists: Array<StartlistMenudata>
}

export interface StartlistMenudata {
    ref: string,
    name: string,
    participantCount: number
}

export interface ActivityData {
    header: ActivityHeader,
    practitioners: Array<PractitionerResultData>
}

export interface ResultDataSaveRequest {
    participationRef: string,
    outOfCompetition: boolean,
    results: TestResultInput[]
}

export interface TestResultInput {
    testResultId: string
    result: SportResultInput
}

export class TestResultInputUtils {
    static toTestResultInput(result: TestResult): TestResultInput {
        return {
            testResultId: result.testResultId,
            result: result.input
        } as TestResultInput
    }
}

export const activityService = new ActivityService()