import { render, staticRenderFns } from "./TableCellRankingFieldComponent.vue?vue&type=template&id=cf07f614&scoped=true"
import script from "./TableCellRankingFieldComponent.vue?vue&type=script&lang=ts"
export * from "./TableCellRankingFieldComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf07f614",
  null
  
)

export default component.exports