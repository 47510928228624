import {Practitioner} from "@/types/competition/Practitioner";
import {SportResultStatus} from "@/types/competition/SportResult";

export interface CompetitionRankingEntry {
    participationRef: string,
    order: number,
    rank?: number,
    practitioner: Practitioner,
    outOfCompetition: boolean,
    results: Array<RankingField>
}

export interface RankingFieldHeader {
    id: string,
    name: string,
    order: number,
    viewPriority: number,
    dataType: RankingFieldType,
}

export enum RankingFieldType {
    POINTS = "POINTS",
    PERCENTAGE = "PERCENTAGE",
    MONEY = "MONEY",
    SHOW_JUMPING = "SHOW_JUMPING",
    DRESSAGE = "DRESSAGE",
    CROSS_COUNTRY = "CROSS_COUNTRY",
    EVENTING = "EVENTING",
}

export interface RankingField {
    type: RankingFieldType;
    id: string;
    order: number;
    visible: boolean;
}

export interface PointsField extends RankingField {
    points?: number
}

export interface PercentageField extends RankingField {
    percentage?: number
}

export interface MoneyField extends RankingField {
    value?: number
    currency?: string
}

export interface JumpingField extends RankingField {
    status: SportResultStatus;
    time?: number;
    obstacleErrorTotal?: number;
    timeErrorTotal?: number;
    totalErrors?: number;
}

export interface DressageField extends RankingField {
    status: SportResultStatus;
    totalPoints?: number;
    totalPercentage?: number;
}

export interface CrossCountryField extends RankingField {
    status: SportResultStatus;
    time?: number;
    obstacleErrorTotal?: number;
    timeErrorTotal?: number;
    totalErrors?: number;
}

export interface EventingField extends RankingField {
    status: SportResultStatus;
    penalties?: number;
    penaltiesCrossCountry?: number;
    statusCrossCountry?: SportResultStatus;
    penaltiesDressage?: number;
    statusDressage?: SportResultStatus;
    penaltiesJumping?: number;
    statusJumping?: SportResultStatus;
}