import {CrossCountryObstacleErrorSpecification} from "@/types/competition/Activity";
import {
    CrossCountryObstacle,
    CrossCountryObstacleErrorResult,
    CrossCountryObstacleErrorType, CrossCountrySportResultInput
} from "@/types/competition/SportResult";

export class CrossCountryPenaltiesHelper {
    constructor(readonly obstacleErrorTypes:Array<CrossCountryObstacleErrorSpecificationUi>, readonly input:CrossCountrySportResultInput) {
    }

    getObstacleErrorByType(type: CrossCountryObstacleErrorType) {
        return this.obstacleErrorTypes.find(it => it.type == type)
    }

    getObstacleResult(obstacle: CrossCountryObstacle) {
        let o = this.input.errors.find(it => it.obstacle.order == obstacle.order)
        if (!o) {
            o = {
                obstacle: obstacle,
                errors: []
            } as CrossCountryObstacleErrorResult
            this.input.errors.push(o)
            return o
        } else {
            return o
        }
    }

    summary(obstacle: CrossCountryObstacle) {
        const errors = this.getObstacleResult(obstacle).errors
        if (errors.length == 1) {
            return this.getObstacleErrorByType(errors[0])!.shortLabel
        } else {
            return "#"
        }
    }

    summaryVariant(obstacle: CrossCountryObstacle): string {
        const errors = this.getObstacleResult(obstacle).errors
        if (errors.length == 0) {
            return ""
        }
        return errors.filter(it => it != CrossCountryObstacleErrorType.NO_ERROR).length == 0 ? "success" : "danger"
    }

    tapObstacle(specification: CrossCountryObstacleErrorSpecification, obstacle: CrossCountryObstacle) {
        const whenOn = (r: CrossCountryObstacleErrorResult) => {
            if (specification.noError) {
                r.errors = r.errors.filter(it => this.obstacleErrorTypes.find(i => i.type == it)?.noError)
            } else {
                r.errors = r.errors.filter(it => !this.obstacleErrorTypes.find(i => i.type == it)?.noError)
            }
        }
        if (this.isMulti(specification)) {
            this.tap(obstacle, specification.type, specification.maxCount || 1, whenOn)
        } else {
            this.toggle(obstacle, specification.type, whenOn)
        }
    }

    isNoError(specification: CrossCountryObstacleErrorSpecification) {
        return specification.noError == true
    }

    isPresent(specification: CrossCountryObstacleErrorSpecification, obstacle: CrossCountryObstacle) {
        return this.count(obstacle, specification.type) > 0
    }

    isMulti(specification: CrossCountryObstacleErrorSpecification) {
        return specification.maxCount > 1
    }

    getCount(specification: CrossCountryObstacleErrorSpecification, obstacle: CrossCountryObstacle) {
        return this.count(obstacle, specification.type)
    }

    toggle(obstacle: CrossCountryObstacle, type: CrossCountryObstacleErrorType, whenOn: (result: CrossCountryObstacleErrorResult) => void) {
        const result = this.getObstacleResult(obstacle)
        const index = result.errors.indexOf(type)
        if (index == -1) {
            whenOn(result)
            result.errors.push(type)
        } else {
            result.errors.splice(index, 1)
        }
    }

    tap(obstacle: CrossCountryObstacle, type: CrossCountryObstacleErrorType, max: number, whenOn: (result: CrossCountryObstacleErrorResult) => void) {
        const result = this.getObstacleResult(obstacle)
        const r = result.errors.filter(it => it == type)
        if (r.length >= max) {
            let i
            while ((i = result.errors.indexOf(type)) >= 0) {
                result.errors.splice(i, 1)
            }
        } else {
            whenOn(result)
            result.errors.push(type)
        }
    }

    count(obstacle: CrossCountryObstacle, type: CrossCountryObstacleErrorType) {
        const result = this.getObstacleResult(obstacle)
        return result.errors.filter(it => it == type).length
    }
}

export interface CrossCountryObstacleErrorSpecificationUi extends CrossCountryObstacleErrorSpecification {
    label: string,
    shortLabel: string,
    variant?: string
}