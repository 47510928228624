












































import {Ref, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {BModal} from "bootstrap-vue";
import {useSelectedRankingStore} from "@/stores/Ranking";
import {RankingResponse} from "@/service/RankingService";
import PractitionerComponent from "@/components/result/PractitionerComponent.vue";
import TableCellRankingFieldComponent from "@/components/result/field/TableCellRankingFieldComponent.vue";
import {RankingField, RankingFieldHeader} from "@/types/competition/Ranking";

@Component({
  components: {TableCellRankingFieldComponent, PractitionerComponent}
})
export default class RankingModal extends Vue {
  private visible = false
  private loading = true
  private ranking: RankingResponse = {} as RankingResponse
  @Ref("obstacleModal") modal!: BModal

  async load() {
    this.loading = true
    this.ranking = await useSelectedRankingStore().loadRanking()
    this.loading = false
  }

  result(header: RankingFieldHeader, result: Array<RankingField>) {
    return result.find(it => it.id == header.id)
  }

  async print() {
    await this.$htmlToPaper('printMe', {
      windowTitle: `${this.ranking.test.name} (${this.ranking.testSet.name}) - ${this.ranking.name}`
    });
  }
}
