












import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {CompetitionInfo} from "@/types/competition/Competition";

@Component({})
export default class CompetitionHeader extends Vue {
  @Prop({}) competition!: CompetitionInfo
}
