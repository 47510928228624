






import {Vue} from "vue-property-decorator";
import Component from "vue-class-component";

@Component({
  components: {}
})
export default class AuthLayout extends Vue {
}
