import {TestResult} from "@/types/competition/SportResult";

export enum PractitionerType {
    INDIVIDUAL = 'INDIVIDUAL',
    TEAM = 'TEAM',
    HORSE = 'HORSE'
}

export interface PractitionerResultData {
    practitioner: Practitioner,
    outOfCompetition: boolean,
    participationRef: string,
    results: Array<TestResult>,
    changed: boolean
}

export interface Practitioner {
    type: PractitionerType;
    ref: string;
    competitionNumber: number,
    position: number
}

export interface IndividualCombination extends Practitioner {
    rider: RiderName,
    horse: HorseName,
    organisation?: OrganisationName,
}

export interface HorseOnly extends Practitioner {
    presenter?: PersonName,
    horse: HorseName,
    organisation?: OrganisationName,
}

export interface Team extends Practitioner {
    name: string,
    organisation?: OrganisationName,
    nationality?: string
}

export interface Person {
    firstName: string;
    lastName: string;
    nationality: string;
}

export type RiderName = PersonName

export interface PersonName {
    ref: string;
    name: string;
    nationality: string;
}

export interface OrganisationName {
    ref?: string;
    site?: string;
    name: string;
}

export interface HorseName {
    ref?: string;
    name: string;
    detail?: string;
}

export interface HorseAttributes {
    isPony?: boolean;
    name?: string;
    withersHeight?: string;
    gender?: string;
    chip?: string;
    ueln?: string;
    birthDate?: string;
    studbook?: string;
    color?: string;
}

